// Variables
// =================================
@import "./styles/color-palette";
@import "./styles/mixins";

// Defaults and Typography

.app {
  @include theme-aware("background-color", "color-background");
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0;
  min-height: 100vh;
  padding-bottom: 20px;
  position: relative;
  transition: margin 0.3s ease-out 0s;

  font-family: $font-regular;
  font-size: $font-size-default;

  h1 {
    font-size: $h1-font-size;

    @media (max-width: $viewport-md) {
      font-size: $h1-font-size-mobile;
    }
  }

  h2 {
    font-size: $h2-font-size;

    @media (max-width: $viewport-md) {
      font-size: $h2-font-size-mobile;
    }
  }

  h3 {
    font-size: $h3-font-size;

    @media (max-width: $viewport-md) {
      font-size: $h3-font-size-mobile;
    }
  }

  h4 {
    font-size: $h4-font-size;

    @media (max-width: $viewport-md) {
      font-size: $h4-font-size-mobile;
    }
  }

  .font-p {
    @media (max-width: $viewport-md) {
      font-size: 14px;
    }
  }

  .Toastify__toast-container--top-right {
    padding: env(safe-area-inset-top) env(safe-area-inset-right)
      env(safe-area-inset-bottom) env(safe-area-inset-left);
  }
}

h1,
h2,
h3 {
  &.title {
    font-family: $font-bold;
    @include theme-aware("color", "title");
  }
}

.heading {
  margin-top: 40px;
  margin-bottom: 20px;
}

.subheading {
  margin-top: 10px;
  margin-bottom: 25px;
}

.small {
  font-size: 14px;
}

.link-more,
button.link-more,
a.link-more {
  text-decoration: underline;
  color: $primary-theme-color;
  font-family: $font-medium;

  &:hover {
    color: $primary-theme-color;
    cursor: pointer;
  }

  // &.active {
  // text-decoration: none;
  // &:hover {
  //   color: inherit;
  //   cursor: default;
  // }
  // }
}

.text-link {
  text-decoration: none;
  cursor: pointer;
  color: $primary-theme-color;

  &:hover:not(.disabled) {
    color: $primary-theme-color;
  }
}

.bold {
  font-family: $font-bold;
}

.italic {
  font-style: italic;
}

.font-color-primary {
  color: $primary-theme-color;
}

.text-grey {
  font-family: $font-medium;
  color: $color-light-grey;
}

.text-grey-bold {
  font-family: $font-bold;
  color: $color-light-grey;
}

.required {
  color: $color-red;
  font-family: $font-bold;
  font-size: $font-size-small;
}

.disabled {
  cursor: not-allowed;
  pointer-events: all;
}

.logo_pic {
  width: $logo_size;
  height: $logo_size;
}

hr[class*="divider-"] {
  background-color: rgba(24, 24, 24, 0.25);
}

.divider {
  color: $color-grey;
  background-color: $color-grey;
  opacity: 0.45;
}

// Layout

.public-page-container {
  margin-top: 50px;

  &::before {
    background: url("./assets/img/abstract_purple_shapes.svg") top right
      no-repeat;
    content: "";
    background-size: auto contain;
    display: block;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    right: 0;
  }
}

// Cards

.card.card-custom {
  @include theme-aware("background-color", "table-background");
  @include theme-aware("box-shadow", "shadow");
  border: 0;
  margin-bottom: 20px;

  .brand_logo {
    filter: brightness(0);
  }

  .card-body {
    padding: 1.5rem;

    @media (max-width: $mobile-width) {
      padding: 1.25rem;
    }
  }
}

// Pill Badges

span.badge {
  font-weight: 800;
  padding: 6px 10px;
  font-size: 80%;

  &.badge-success {
    @include theme-aware("background-color", "badge-success-bg");
    @include theme-aware("color", "badge-success-color");
  }

  &.badge-warning {
    @include theme-aware("background-color", "badge-warning-bg");
    @include theme-aware("color", "badge-warning-color");
  }

  &.badge-danger {
    @include theme-aware("background-color", "badge-danger-bg");
    @include theme-aware("color", "badge-danger-color");
  }

  &.badge-neutral {
    @include theme-aware("background-color", "badge-neutral-bg");
    @include theme-aware("color", "badge-neutral-color");
  }

  &.badge-light {
    @include theme-aware("background-color", "hover");
    @include theme-aware("color", "badge-info-color");
  }
}

// Tables

.table-outer {
  @include border-radius($blocks-radius);
  @include theme-aware("box-shadow", "shadow");
  @include theme-aware("background", "table-background");

  font-size: 14px;
  padding: 15px;

  @media (max-width: $mobile-width) {
    padding: 0;
  }

  table.table {
    margin-bottom: 0;
  }
}

table.table {
  font-family: $font-regular;

  tr {
    th,
    td {
      vertical-align: middle;
    }
  }

  thead {
    tr {
      th {
        font-weight: 600;
        font-size: $font-size-small;
        line-height: 14px;
        color: $color-light-grey;
      }
    }
  }

  tbody {
    tr {
      th {
        @include theme-aware("color", "title");
        font-weight: 700;
        font-size: $font-size-default;
      }
    }
  }

  &.table-hover > tbody {
    tr:hover {
      background-color: $color-grey;
    }
    .selected {
      @include theme-aware("background-color", "color-background");
    }
  }
}

// Forms

.form-group {
  position: relative;
  margin-bottom: 28px;

  .single-select {
    @include theme-aware("background-color", "dropdown-background");
    @include theme-aware("box-shadow", "shadow-dark");
    @include theme-aware("color", "title");
    @include border-radius($blocks-radius);
    @include theme-aware("border-color", "dropdown-hover");
    border: 2px solid;
    font-weight: 700;

    .css-1uccc91-singleValue {
      color: $color-light-grey;
    }
    .css-1hb7zxy-IndicatorsContainer {
      .css-1okebmr-indicatorSeparator {
        @include theme-aware("background-color", "dropdown-hover");
      }
      .css-1gtu0rj-indicatorContainer,
      .css-tlfecz-indicatorContainer {
        padding: 12px;

        svg {
          width: 22px;
          height: 22px;
        }
      }
    }

    // overflow: visible;

    height: 48px;
    margin-bottom: 20px;

    &:focus,
    &:focus-within,
    &:active {
      border-color: $primary-theme-color;
    }

    & > div[class*="-control"] {
      height: 100%;
      border: none;
      box-shadow: none;

      &:focus,
      &:active {
        border: 1px solid;
        border-color: $primary-theme-color;
        // box-shadow: none;
      }
    }

    & > div[class*="-menu"] {
      @include border-radius($blocks-radius);
      @include theme-aware("border-color", "dropdown-hover");
      @include theme-aware("box-shadow", "shadow-dark");
      @include theme-aware("color", "title");
      border: 1px solid;

      div[class*="-option"] {
        &:focus,
        &:active,
        &:hover {
          @include theme-aware("background-color", "dropdown-hover");
        }
      }
    }
  }

  .file-input-hidden {
    opacity: 0; // * hide using opacity instead of display none/hidden for accessibility
    height: 0;
    width: 0;
    position: absolute;
  }

  .form-label {
    @include theme-aware("color", "badge-info-color");
    font-family: $font-bold;
    font-size: $font-size-small;
    margin-bottom: 0.25rem;
  }

  .form-control {
    @include theme-aware("background-color", "dropdown-background");
    @include theme-aware("border-color", "dropdown-hover");
    @include theme-aware("box-shadow", "shadow-dark");
    @include theme-aware("color", "title");
    font-weight: 700;
    @include border-radius($blocks-radius);

    height: 48px;
    padding-left: 16px;
    padding-right: 24px;
    margin-bottom: 8px;

    &.text-area {
      height: calc(
        1.5em * 3 + 6px + 6px + 1px + 1px
      ); /* 5 lines with 1.5em line height, 6px top and bottom padding, 1px top and bottom border */
    }

    &.has-icon-right {
      padding-right: 50px;
    }

    &.stripe-iban-input {
      padding: 12px 24px;
      height: auto;
    }

    &:focus {
      border: 1px solid $primary-theme-color;
    }

    &.error {
      border: 1px solid $color-red;
    }

    ::-ms-reveal {
      display: none;
    }
  }

  .input-group.input-with-addon {
    // display box shadows on parent group
    @include theme-aware("background-color", "dropdown-background");
    // @include theme-aware("border-color", "dropdown-hover");
    @include theme-aware("box-shadow", "shadow-dark");

    margin-bottom: 8px;

    > * {
      @include border-radius($blocks-radius);
    }

    *:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    *:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    > .form-control,
    > [class^="input-addon-"] {
      box-shadow: none;
      &:not(:last-child) {
        margin-right: -1px;
      }
      &:last-child {
        margin-left: -1px;
      }

      &:focus {
        &,
        & ~ * {
          &:not(:last-child) {
            @include theme-aware("border-right-color", "dropdown-hover");
            border-bottom-color: $primary-theme-color;
            border-top-color: $primary-theme-color;
            border-left-color: $primary-theme-color;
          }
          &:last-child {
            @include theme-aware("border-left-color", "dropdown-hover");
            border-bottom-color: $primary-theme-color;
            border-top-color: $primary-theme-color;
            border-right-color: $primary-theme-color;
          }
        }
      }
      &.error {
        &,
        & ~ * {
          &:not(:last-child) {
            @include theme-aware("border-right-color", "dropdown-hover");
            border-bottom-color: $color-red;
            border-top-color: $color-red;
            border-left-color: $color-red;
          }
          &:last-child {
            @include theme-aware("border-left-color", "dropdown-hover");
            border-bottom-color: $color-red;
            border-top-color: $color-red;
            border-right-color: $color-red;
          }
        }
      }
    }

    .input-addon-btn {
      z-index: 2;
      @include theme-aware("border-color", "dropdown-hover");
      @include theme-aware("color", "icon-text");
      font-size: $font-size-small;

      &:hover {
        @include theme-aware("background-color", "dropdown-background");
        @include theme-aware("color", "icon-text");
      }
    }

    .input-addon-text {
      display: inline-flex;
      align-items: center;
      padding: 0 12px 0 8px;
      color: var(--grey-neutral-grey-800, #1a1b25);
      border: 1px solid transparent;
      font-family: $font-bold;
      font-size: 18px;

      &.error {
        border: 1px solid $color-red;
      }
    }
  }

  margin: 0;
}

.styled-checkbox {
  accent-color: $primary-theme-color;
}

// Nav

.outer-header {
  @include theme-aware("background-color", "sidebar-background");
  @include theme-aware("box-shadow", "shadow");

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 100%;
  width: 300px;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  overflow-x: hidden;
  padding-top: 20px;

  &.show {
    transition: width 1s cubic-bezier(0, 1, 0.5, 1);
  }

  &.collapsed {
    width: 7%;

    transition: width 1s cubic-bezier(0, 1, 0.5, 1);

    .header-container {
      display: flex;
      flex-flow: column nowrap;

      .header-inner-container {
        width: 50%;
      }

      .top_logo {
        transform: scale(0.65);
      }

      .profile-main {
        position: absolute;
        bottom: 10px;

        .profile-main-container {
          .profile-container {
            transition: 1s cubic-bezier(0, 1, 0.5, 1);
          }
        }
      }

      .link-container {
        .link {
          padding: 15px 5px;

          display: flex;
          justify-content: center;

          .icon-container {
            padding-left: 0;
          }
        }

        .sidebar-page {
          display: none;
        }
      }
    }
  }

  @media (max-width: $mobile-width) {
    height: initial;
    width: 100%;
    position: initial;
    padding-top: 0px;
    overflow: initial;
  }

  .mobile-header-container {
    @include theme-aware("box-shadow", "shadow-dark");
    position: fixed;
    width: 100vw;
    background-color: inherit;
    padding-top: env(safe-area-inset-top);

    .navbar-toggler {
      svg {
        color: $primary-theme-color;
        stroke-width: 2;
      }
    }

    .navbar-brand {
      filter: brightness(0);
    }

    .link-container {
      transition: all 1s cubic-bezier(0, 1, 0.5, 1);
      justify-content: space-between;
      height: 100vh;

      .link {
        padding: 15px;
        display: flex;
        text-decoration: none;
        font-size: 15px;
        color: #818181;
        margin-top: 10px;

        .icon-container {
          margin-top: -1px;
          padding-left: 5px;
        }

        .report-icon {
          margin-top: -2px;
        }

        span {
          display: inline-block;
          font-size: 20px;
          font-family: $font-bold;

          &.sidebar-page {
            padding-left: 10px;
          }
        }

        &:hover {
          background: $primary-theme-color-transparent;
          border-radius: 6px;
          color: $primary-theme-color;

          .link-icon {
            path {
              color: $primary-theme-color;
            }
          }
        }

        &.disabled:hover {
          background: none;
          color: #818181;
          cursor: pointer;

          .link-icon {
            path {
              color: #818181;
            }
          }
        }
      }

      .active {
        background: $primary-theme-color-transparent;
        color: $primary-theme-color;

        .link-icon {
          path {
            color: $primary-theme-color;
          }
        }
      }

      .logout {
        margin-bottom: calc(env(safe-area-inset-bottom) + 115px);
        align-self: center;

        hr {
          color: $color-grey;
          background-color: $color-grey;
          margin: 0 0 20px 0;
          opacity: 0.25;
        }

        .btn {
          width: 80vw;
          border: none;
          font-size: 18px;
          font-family: $font-bold;
          text-decoration: underline;
        }
      }
    }
  }

  .header-container {
    padding-top: 30px;
    height: 100%;

    align-items: center;

    .top_logo {
      margin: auto;
      width: $top_logo;
      display: block;

      transform: scale(1);
      transition: transform 1s cubic-bezier(0, 1, 0.5, 1);
      filter: brightness(0);
    }

    .header-inner-container {
      width: 230px;
      margin: auto;
      margin-top: 0;
    }

    .link-container {
      padding-top: 15px;
      transition: all 1s cubic-bezier(0, 1, 0.5, 1);

      .link {
        padding: 15px 15px 15px 5px;
        display: flex;
        text-decoration: none;
        font-size: 15px;
        color: #818181;
        // font-weight: 700;
        margin-top: 10px;

        .icon-container {
          margin-top: -1px;
          padding-left: 5px;
        }

        .report-icon {
          margin-top: -2px;
        }

        .free-report-icon {
          background: #30c62e;
          color: #fff;
          font-size: 12px;

          border: none;
          border-radius: 12px;
          padding: 3px 10px;
          margin-left: 15px;
        }

        span {
          display: inline-block;
          font-size: 20px;
          font-family: $font-bold;

          &.sidebar-page {
            padding-left: 10px;
          }
        }

        &:hover {
          background: $primary-theme-color-transparent;
          border-radius: 6px;
          color: $primary-theme-color;

          .link-icon {
            path {
              color: $primary-theme-color;
            }
          }
        }

        &.disabled:hover {
          background: none;
          color: #818181;
          cursor: pointer;

          .link-icon {
            path {
              color: #818181;
            }
          }
        }
      }

      .active {
        background: $primary-theme-color-transparent;
        color: $primary-theme-color;
        border-radius: 6px;

        .link-icon {
          path {
            color: $primary-theme-color;
          }
        }
      }
    }

    .profile-main {
      position: absolute;
      bottom: 10px;

      left: 50%;
      transform: translateX(-50%);

      .settings-icon {
        @include theme-aware("background-color", "color-background");

        width: 50px;
        height: 50px;
        padding: 10px;
        border-radius: 50%;

        path {
          @include theme-aware("color", "icon-sidebar");
        }
      }

      .subscribe {
        display: block;
        margin: auto;
        padding: 13px 35px 13px 35px;
        margin-bottom: 20px;
      }

      .profile-main-container {
        transition: all 0.3s cubic-bezier(0.4, 0, 1, 1);

        .btn {
          border: none;
          font-size: 18px;
          font-family: $font-bold;
          text-decoration: underline;

          &:hover {
            color: $color-red;
            background-color: white;
          }
        }

        .profile-container {
          padding: 0px 10px 10px 10px;

          .profile-card {
            display: flex;
            cursor: pointer;

            .profile-left {
              img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
              }
            }

            .profile-middle {
              padding-left: 20px;
              width: 100%;

              .user {
                font-size: 10px;
                padding-top: 5px;
              }

              .name {
                font-weight: 700;
              }
            }

            .profile-right {
              .arrow-container {
                padding-top: 10px;
                cursor: pointer;

                i {
                  position: absolute;
                }

                .fa-angle-down {
                  padding-top: 10px;
                }
              }
            }
          }
        }

        .profile-balance-container {
          overflow: hidden;
          transition: all 0.4s cubic-bezier(0.4, 0, 1, 1);
          transform-origin: left top;
          height: 0;

          .balance-items-container {
            @include theme-aware("background-color", "color-background");
            @include theme-aware("box-shadow", "shadow-dark");

            border-radius: 16px;
            width: 200px;
            padding: 15px;
            margin: auto;

            .balance-item {
              font-weight: 700;
              display: flex;
              justify-content: space-between;
              margin-top: 20px;

              &.first {
                margin-top: 0;
                justify-content: center;
              }
            }
          }
        }

        .open {
          overflow: hidden;
          transition: all 0.4s cubic-bezier(0.4, 0, 1, 1);
          height: auto;
        }

        .action-container {
          transition: all 0.5s ease-in;
          font-weight: 700;

          .action {
            cursor: pointer;
            display: flex;
            margin-top: 20px;
            justify-content: space-between;
          }

          .sign-out {
            font-family: $font-bold;
            color: $danger-button-bg-color;
          }
        }
      }
    }
  }
}

.outer-header-hidden {
  display: none;
}

.outer-header + .content {
  @include theme-aware("background-color", "color-background");
  padding-top: env(safe-area-inset-top);

  @media (min-width: $mobile-width) {
    margin-left: 300px;
  }
}

.nav-back-arrow {
  position: absolute;
  top: 40px;
  color: $color-dark-grey;
  z-index: 1;

  @media (max-width: $viewport-xl) {
    display: none;
  }
}

.nav-tabbed-custom {
  &:not(.no-well) {
    .tab-pane {
      @include theme-aware("background", "table-background");
      @include theme-aware("box-shadow", "shadow");
      border-radius: 0.25rem;
    }
  }

  .tab-pane {
    background-clip: border-box;
    padding: 10px 30px 30px;
  }

  .nav-item {
    cursor: pointer;
  }

  .nav-pills {
    .nav-item {
      &:not(:last-child) {
        margin-right: 5px;
      }
    }
    .nav-link {
      @include theme-aware("box-shadow", "shadow");
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem 0.75rem;
      border-radius: 0.25rem;

      &.active {
        background-color: $primary-button-bg-color;
        color: $color-white;

        &:hover {
          background-color: $primary-theme-color;
        }
      }

      font-family: $font-bold;
      background-color: $tags-background-color;
      color: $color-dark-grey;

      &:hover {
        cursor: pointer;
        background-color: $color-xlight-grey;
      }
    }
  }
}

// Accordion

.accordion-custom {
  .card {
    border: 2px solid;
    border-radius: 0;
    border-color: $primary-theme-color-transparent;

    &:not(:last-child) {
      margin-bottom: 2px;
      border-bottom: #fff;
    }

    &:first-child {
      border-radius: $blocks-radius $blocks-radius 0px 0px;
      -webkit-border-radius: $blocks-radius $blocks-radius 0px 0px;
      -ms-border-radius: $blocks-radius $blocks-radius 0px 0px;
    }

    &:last-child {
      border-radius: 0px 0px $blocks-radius $blocks-radius;
      -webkit-border-radius: 0px 0px $blocks-radius $blocks-radius;
    }

    .card-header {
      background-color: $primary-theme-color-transparent;
      font-family: $font-bold;
      padding: 12px 18px;
      color: $primary-theme-color;
      border-color: transparent;
    }
  }
}

// Buttons

.btn {
  font-size: $font-size-default;
  @include border-radius($blocks-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.btn-default {
  @include theme-aware("box-shadow", "shadow");
  background: #fff;
  font-family: $font-bold;
  &.btn {
    padding: 12px 18px;
  }
}

.btn.btn-primary {
  color: #fff;
  border: 0;
  background: $primary-button-bg-color;
  border-radius: $blocks-radius;
  padding: 12px 18px;
  font-family: $font-bold;

  @include box-shadow($primary-button-shadow);

  &:focus,
  &.focus,
  &:hover {
    background: $primary-button-hover-outline-color;
    border: 0;
    color: $color-white;
  }

  &:disabled,
  &:active,
  &.active,
  &:not(:disabled):not(.disabled):active {
    background: $primary-button-hover-outline-color;
    border: 0;
    color: $color-white;
  }
}

.btn.btn-outline-primary {
  color: $primary-button-outline-color;
  border-color: $primary-button-outline-color;
  background: $color-white;
  font-family: $font-bold;
  border: 2px solid;

  @include theme-aware("background", "modal-background");
  border-radius: $blocks-radius;

  &:hover {
    background: $primary-button-hover-outline-color;
    color: #fff;
    border-color: $primary-button-hover-outline-color;

    @include box-shadow($primary-button-hover-outline-shadow);
  }

  &:focus,
  &:active,
  &:visited,
  &:not(:disabled):not(.disabled):active {
    @include theme-aware("background", "modal-background");
    color: $primary-button-active-outline-color;
    border-color: $primary-button-active-outline-color;

    @include box-shadow($primary-button-active-outline-shadow);
  }

  &:disabled {
    background: $color-white;
    color: $primary-button-hover-outline-color;
    border-color: $primary-button-hover-outline-color;
  }
}

.link-as-btn {
  text-decoration: none;
  &:hover {
    text-decoration: none;
    &.btn-outline-primary {
      &:visited {
        background: $primary-button-hover-outline-color;
        color: #fff;
        border-color: $primary-button-hover-outline-color;
      }
    }
    // TODO: fix hover states for btn-primary
  }
}

.btn-as-link {
  color: inherit;
  background: none;
  box-shadow: none;
  border: 1px solid transparent;
}

.btn-stepper {
  width: 100%;
  height: 48px;

  @media (max-width: $viewport-md) {
    width: 45%;
  }
}

.btn.btn-wide,
.btn.btn-default.btn-wide {
  width: 100%;
  height: 48px;
}

.btn-inline-form-field {
  height: 48px;
  min-width: 48px;
  margin-top: 27px;
}

// Popovers

.popover.show {
  @include theme-aware("background-color", "table-background");
  border-color: $border-color__light;
  @include border-radius($blocks-radius);
  // border: 0;
  margin-bottom: 20px;

  .popover-body {
    padding: 1.5rem;
  }
}

.popover.show.bs-popover-auto[x-placement^="top"],
.popover.show.bs-popover-top {
  > .arrow:after {
    border-top-color: white;
  }

  > .arrow:before {
    border-top-color: $border-color__light;
  }
}

.popover.show.bs-popover-auto[x-placement^="bottom"],
.popover.show.bs-popover-bottom {
  > .arrow:after {
    border-bottom-color: white;
  }

  > .arrow:before {
    border-bottom-color: $border-color__light;
  }
}

.popover.show.bs-popover-auto[x-placement^="right"],
.popover.show.bs-popover-right {
  > .arrow:after {
    border-right-color: white;
  }

  > .arrow:before {
    border-right-color: $border-color__light;
  }
}

.popover.show.bs-popover-auto[x-placement^="left"],
.popover.show.bs-popover-left {
  > .arrow:after {
    border-left-color: white;
  }

  > .arrow:before {
    border-left-color: $border-color__light;
  }
}

// Modals

.modal {
  .modal-title {
    display: flex;
    align-items: center;
  }
}

// Alerts

.alert {
  font-family: $font-medium;
  svg {
    margin-right: 1rem;

    @media (max-width: $viewport-md) {
      margin-right: 0.65rem;
    }
  }

  .action {
    @media (max-width: $viewport-sm) {
      display: none;
    }
  }

  &.alert-success,
  &.alert-warning {
    @media (max-width: $viewport-md) {
      padding: 0.8rem 0.5rem;
      font-size: $h3-font-size-mobile;
      line-height: 22px;
    }
  }
}

.alert.alert-primary.show {
  background: $primary-theme-color-transparent;
  border-radius: 6px;
  color: $primary-theme-color;
}

// Loading Spinner

.loader {
  @include theme-aware("background-color", "loader");
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1150;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  color: $primary-theme-color;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

// Misc Components

.currency-display {
  display: inline-flex;

  .currency > .code {
    font-size: clamp(12px, 0.6em, 24px);
  }
  .icon-container {
    display: inline-flex;
    align-items: center;
  }
}

.tooltip-target-icon {
  cursor: pointer;
  margin-left: 5px;
  color: $color-light-grey;
}

.tooltip {
  .tooltip-inner {
    font-family: $font-regular;
    font-size: $font-size-small;
    max-width: 275px;
    padding: 15px 12px 18px;
    @include theme-aware("box-shadow", "shadow");
    @include theme-aware("background-color", "tooltip-background");
    @include theme-aware("color", "tooltip-text");
  }
  &.show[class*="bs-tooltip-"] {
    opacity: 1;
  }

  &.bs-tooltip-top .arrow::before {
    @include theme-aware("border-top-color", "tooltip-background");
  }
  &.bs-tooltip-bottom .arrow::before {
    @include theme-aware("border-bottom-color", "tooltip-background");
  }
  &.bs-tooltip-left .arrow::before {
    @include theme-aware("border-left-color", "tooltip-background");
  }
  &.bs-tooltip-right .arrow::before {
    @include theme-aware("border-right-color", "tooltip-background");
  }
}

.social-icon-img {
  @include theme-aware("box-shadow", "image-shadow");
  @include theme-aware("border-color", "dropdown-background");
  border: 2px solid;

  width: 30px;
  height: 30px;
  border-radius: 50%;

  &.instagram {
    background: radial-gradient(
      circle at 33% 100%,
      #fed373 4%,
      #f15245 30%,
      #d92e7f 62%,
      #9b36b7 85%,
      #515ecf
    );
  }

  &.youtube {
    background-color: #e02f2f;
  }

  &.tiktok {
    background-color: $color-black;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
